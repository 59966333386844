.bloc-details{
  background: $light;
  padding: 0 75px;

  .wrap{
    position: relative;
    top: -8vw;
    background: $white;
    width: 100%;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;

    .content{
      padding: 5vw 7vw;
    }
  }

  .main-text{
    font-size: 18px;
    line-height: 34px;
  }

  .site-btn-center{
    padding-top: 35px;
  }

  @media all and (max-width: 1174px) {

    padding: 0 40px;

    .main-text{
      font-size: 16px;
      line-height: 1.8;
    }

  }

  @media all and (max-width: 767px) {
    padding: 0 0 5px 0;

    .main-text{
      font-size: 14px;
      line-height: 1.7;
    }

    .wrap{
      top: 0;
      padding: 40px 20px;

      .content{
        padding: 40px 0;
      }
    }

  }

}