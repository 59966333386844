.thumbs-services{
  background: #fff;
  padding: 6vw 75px;

  .title2, .title3{
    text-align: center;
    max-width: 445px;
    margin: 0 auto;
  }

  .title2{
    padding-bottom: 60px;
  }

  .list-thumbs{
    position: relative;
    text-align: center;

    -js-display: flex; /* Pollyfill */
    display: flex;

    justify-content: center;
    align-content: stretch;

    max-width: 1600px;
    margin: 0 auto;

    .thumb{
      position: relative;
      display: inline-block;

      max-width: 400px;
      width: 100%;
      padding: 0.4%;
      margin: 0 1%;

      border: 1px solid $light;
      border-radius: 6px;

      background-color: rgba( 0, 0, 0, 0);

      transform: none;
      transition: all ease 0.25s;

      &:hover{
        transform: scale(.98);
        background-color: rgba( 0, 0, 0, .02);
      }

      .wrap{
        padding: 60px 40px;
      }

      i{
        display: inline-block;
        font-size: 60px;
        padding-bottom: 35px;

      }

      .title3{
        max-width: 285px;
        min-height: 90px;
        color: $dark;
        font-size: 19px;
        line-height: 25px;
        letter-spacing: 2px;
        padding-bottom: 40px;
      }

      .main-text{
        padding-bottom: 60px;
        font-size: 14px;
      }

      .link{
        color: $colorAccent1;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 2px;
        font-weight: 600;
        text-transform: uppercase;

        transition: all ease 0.25s;
      }



    }
  }

  @media all and (max-width: 1649px) {
    .list-thumbs{
      .thumb{
        .wrap{
          padding: 60px 25px;
        }
      }
    }
  }

  @media all and (max-width: 1399px) {
    .list-thumbs{
      .thumb{
        padding: 0.2%;

        .wrap{
          padding: 45px 20px;
        }

        i{
          font-size: 50px;
          padding-bottom: 25px;

        }

        .title3{
          font-size: 17px;
          line-height: 1.4;
          letter-spacing: 1px;
          padding-bottom: 20px;
        }

        .main-text{
          font-size: 14px;
          line-height: 1.7;
          padding-bottom: 20px;
        }

        a{
          font-size: 14px;
          line-height: 1;
          letter-spacing: 1px;
        }

      }
    }
  }

  @media all and (max-width: 1174px) {
    padding: 6vw 40px;
  }

  @media all and (max-width: 969px) {
    .list-thumbs{
      flex-wrap: wrap;

      .thumb{
        width: 47%;
        margin-bottom: 2%;
      }

    }
  }

  @media all and (max-width: 767px) {
    padding: 40px 20px;

    .list-thumbs{
      justify-content: center;

      .thumb{
        width: 100%;
        padding-top: 0;
        padding-bottom: 20px;
      }

    }

  }

}