
.site-content{
  padding-top: 70px;
  @media all and (max-width: 1099px) {
    padding-top: 60px;
  }
}

.main-nav,
.nav-links{
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 70px;

  margin: 0;
  padding: 0 0 0 0;

  color: $white;
  background: $dark;
  font-family: $fontTitle;
  letter-spacing: 2px;
  font-weight: 500;

  @extend %clearfix;

  z-index: 15;

  transition: all 0.2s ease-in-out;

  overflow: hidden;

  z-index: 100;

  .logo{
    float: left;
    width: 150px;
    height: auto;
  }

  .left{
    float: left;
  }

  .right{
    float: right;
  }

  .title{
    float: left;
    line-height: 70px;
    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    font-size: .87rem;
  }

  .tel{
    float: left;
    line-height: 70px;
    background: rgba( 0, 0, 0, .15);
    padding: 0 40px;

    opacity: 1;

    transition: all 0.2s ease-in-out;

    &:hover{
      opacity: .70;
    }

    &.mobile{
      display: none;

      float: right;
      width: 60px;
      height: 60px;
      padding: 0;
      font-size: 20px;
      text-align: center;
      font-weight: 600;
      line-height: 60px;
    }

  }

  .site-btn{
    float: left;
  }

  .menu{
    display: none;
    background: $white;
    color: $colorAccent1;

    .i-menu{
      display: inline-block;
    }

    .i-close{
      display: none;
    }

    &.active{
      .i-menu{
        display: none;
      }

      .i-close{
        display: inline-block;
      }
    }
  }

  @media all and (max-width: 1099px) {

    &,
    &.follow{

      height: 60px;

      .logo{
        width: auto;
        height: 60px;
      }

      .title{
        line-height: 60px;
        font-size: 13px;
        letter-spacing: 1px;
      }

      .tel{
        line-height: 60px;
        font-size: 13px;
      }

      .site-btn{
        padding: 24px 25px;
        font-size: 13px;

      }

    }

  }

  @media all and (max-width: 899px) {
    &,
    &.follow{
      .site-btn{
        display: none;
        &.lang{
          display: block;
          background: $white;
          color: $colorAccent1;
        }
      }
    }
  }

  @media all and (max-width: 767px) {

    &,
    &.follow{
      .tel{
        display: none;
        &.mobile{
          display: block;
          font-size: 15px;
        }
      }
      .site-btn{
        float: right;
        &.lang{
          display: block;
          background: rgba(0, 0, 0, 0.15);
          color: #fff;
          float: left;
          border-right: 2px solid rgba(0, 0, 0, 0.15);
        }
        &.menu{
          display: block;
          font-size: 21px;
          padding: 18px 20px;
        }
      }

    }

  }

  @media all and (max-width: 599px) {
    &,
    &.follow{
      .title{
        display: none;
      }
    }
  }

}

.nav-links{
  position: relative;

  background: $darker;
  text-align: center;

  overflow: visible;

  z-index: 2;

  .linkedin{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 22px;
  }

  a{
    position: relative;
    display: inline-block;
    height: 100%;
    padding: 0 25px;
    text-transform: uppercase;
    font-size: 14px;
    font-size: .87rem;
    line-height: 70px;

    &.active{
      &:before{
        content: '';

        position: absolute;
        left: 20%;
        right: 20%;
        bottom: 0;

        margin-bottom: -3px;

        height: 6px;
        background: $colorAccent1;
      }
    }
  }

  @media all and (max-width: 1099px) {
    a{
      line-height: 60px;
      font-size: 13px;
    }
  }

  @media all and (max-width: 767px) {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: auto;
    transform: translate( 100%, 0);

    width: 45%;
    height: auto;
    min-width: 375px;

    z-index: 99;

    &.active{
      transform: translate( 0, 0);
    }

    a{
      display: block;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid rgba(255, 255, 255, .30);

      outline: none;

      &.active:before{
        top: 0;
        right: 0;
        left: auto;
        bottom: 0;
        width: 6px;
        height: auto;
        background: #e41f39;
        margin: 0;
      }

      &.linkedin{
        position: relative;
        border: none;
      }

    }

  }

  @media all and (max-width: 400px) {
    width: 100%;
    min-width: inherit;
  }
}


.nav-links-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: -1;

  background: rgba(0, 0, 0, 0);

  transition: all ease 0.33s;

  @media all and (max-width: 767px) {
    &.active{
      z-index: 98;
      background: rgba(0, 0, 0, .33);
    }
  }

}