.home-intro{

  .title2{
    max-width: 405px;
  }

  .col.half.visual{
    position: absolute;
    top: -45px;
    bottom: 0;
    left: 0;

    background: url('../images/home/intro.jpg') $dark bottom left no-repeat;
    background-size: cover;

  }

  .col.half.text{
    float: right;
    padding: 6vw 75px;
  }

  .main-text{
    max-width: 635px;
  }

  @media all and (max-width: 1280px) {
    .col.half.text{
      padding: 6vw 50px;
    }
  }

  @media all and (max-width: 1174px) {
    .col.half.text{
      padding: 6vw 40px;
    }
  }

  @media all and (max-width: 969px) {
    .col.half.visual{
      position: relative;
      top: 0;
      width: 100%;
      height: 40vw;
    }
    .col.half.text{
      width: 100%;
      height: auto;
    }
  }

  @media all and (max-width: 767px) {
    .col.half.text{
      padding: 40px 20px;
    }
  }

}