
/*
 * 		Colors
 * 		------
 */


$black  : #000;
$white  : #fff;

$grey   : #262624;
$gray   : #262624;

$light  : #DFE4E5;
$dark   : #35383C;
$darker : #202326;

$colorAccent1 : #E41F39;
$colorAccent2 : #F81E37;
$colorAccent3 : #E41F39;
$colorAccent4 : #E41F39;
$colorAccent5 : #E41F39;
$colorAccent6 : #E41F39;




/*
 * 		Fonts
 * 		-----
 */

$fontTitle          : 'brandon-grotesque', 'Helvetica', 'Arial', sans-serif;
$fontText           : 'open-sans', 'Helvetica', 'Arial', sans-serif;
$fontSpecial        : 'mrs-eaves-xl-serif', 'brandon-grotesque', 'Helvetica', 'Arial', sans-serif;




/*
 * 		Breakpoints
 * 		-----------
 */

