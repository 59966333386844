.service{
  background: #DFE4E5;

  .title1{
    max-width: 850px;
    margin: 0 auto;
  }

  .service-hero{
    background: url('../images/hero/service.jpg') $light top right no-repeat;
    background-size: cover;

    .content{
      text-align: center;
      max-width: inherit;
    }

    .title2{
      color: $white;
    }
  }

  &.isToronto {
    .service-hero{
      background-image: url('../images/hero/contact-toronto.jpg');
      background-position: center bottom;
    }
  }

  @media all and (max-width: 767px) {
    .title1{
      color: $white;
    }
    .bloc-details .wrap .content{
      padding: 0;
    }
    .bloc-details .wrap .views .content{
      padding: 40px 0;
    }
  }

}


.bloc-arguments{
  padding: 120px;
  text-align: center;
  background: $white;
  margin-bottom: 8vw;

  i{
    color: $grey;
    font-size: 75px;
  }

  .title3{

  }

  .title4{
    padding-top: 15px;
    padding-bottom: 15px;
    color: $darker;
    font-size: 15px;
    line-height: 1.8;
    text-transform: uppercase;
  }

  .main-text{
    text-align: center;
  }

  .arguments{
    padding: 50px 0;
  }

  .argument{
    text-align: center;
    padding: 0 3%;

    img{
      display: inline-block;
      width: auto;
      height: 75px;
      margin-bottom: 15px;
    }

  }

  @media all and (max-width: 1689px) {
    padding-left: 50px;
    padding-right: 50px;

  }

  @media all and (max-width: 1278px) {
    .title4{
      font-size: 12px;
    }

  }

  @media (max-width: 1024px){
    padding: 40px 20px;

  }

  @media all and (max-width: 767px) {
    margin: 0 0 5px 0;
    .arguments{
      padding: 20px 0;
    }

  }

}