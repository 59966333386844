
.site-hr{
  width: 55px;
  height: 5px;
  background: $light;
  margin: 0 auto 25px auto;
  padding: 0;
  border: none;
}

.c-icon-circle{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  width: 50px;
  height: 50px;
  background: $colorAccent2;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: $white;
  font-size: 22px;
  font-style: normal;
}

.site-content{

}

.init-grid{
  position: relative;
  @extend %clearfix;

  .row{
    position: relative;
    @extend %clearfix;

    &.max-width{
      max-width: 1480px;
      margin: 0 auto;
      padding-left: 40px;
      padding-right: 40px;
    }

  }

  .col{
    position: relative;
    width: 100%;
    &.half{
      float: left;
      width: 50%;
    }
    &.one-third{
      float: left;
      width: 33.3333%;

    }
  }
  @media all and (max-width: 767px) {
    .col{
      &.half{
        float: none;
        width: 100%;
      }
      &.one-third{
        float: none;
        width: 100%;
        padding-bottom: 25px;
        .main-text{
          max-width: 300px;
          margin: 0 auto;
        }
      }
    }
  }
}

.map{
  position: relative;
  width: 100%;
  height: 500px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;

  @media all and (max-width: 599px) {
    height: 375px;
  }

}





