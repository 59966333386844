.site-btn-center{
  position: relative;
  text-align: center;
}

.site-btn{
  display: inline-block;
  margin: 0;
  padding: 27px 30px;
  background: $colorAccent1;
  text-align: center;
  color: $white;
  font-size: 17px;
  font-weight: 500;
  font-family: $fontTitle;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;

  transition: all 0.2s ease-in-out;

  &:hover{
    background: $white;
    color: $colorAccent1;
  }

  &.shadow{
    border-bottom: 4px solid rgba(0, 0, 0, .25);
  }

  &.contrast{

    &:hover{
      background: #f2f2f2;
    }
  }

  &.outlined{
    background: transparent;
    border-color: $colorAccent1;
    color: $colorAccent1;

    &:hover{
      background: $colorAccent1;
      border-color: $colorAccent1;
      color: $white;
    }
  }

  &.dark{
    background: $dark;
    &:hover{
      background: $white;
      color: $colorAccent1;
    }
  }

  &.back-btn{
    position: relative;
    i{
      position: absolute;
      top: 50%;
      left: 15px;

      transform: translate( 0, -50%);
    }
  }



  @media all and (max-width: 1099px) {
    padding: 20px 20px;
    font-size: 15px;
  }

}