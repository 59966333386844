
@import 'fonts';

/*
 * 		External Ressources & vendors
 * 		-------------------
 */


@import 'vendors/sweetalert';
@import 'vendors/parsley';
@import 'vendors/flickity';



/*
 * 		Settings
 * 		--------
 */


@import 'settings';


/*
 *    Base
 *    --------
 */


@import 'base/normalize';
@import 'base/global';
@import 'mixins';


/*
 * 		Components
 * 		----------
 */


@import 'components/btns';
@import 'components/typography';
@import 'components/datepicker';
@import 'components/loader';



/*
 * 		Modules
 * 		-------
 */


@import 'modules/block';
@import 'modules/main-nav';
@import 'modules/home-hero';
@import 'modules/home-intro';
@import 'modules/thumbs-equipe';
@import 'modules/thumbs-services';
@import 'modules/thumbs-carrieres';
@import 'modules/thumbs-publications';
@import 'modules/bloc-map';
@import 'modules/footer-nav';
@import 'modules/custom-popup';
@import 'modules/forms';
@import 'modules/bloc-details';
@import 'modules/switch-views';
@import 'modules/profil-equipe';
@import 'modules/info-carrieres';


/*
 * 		Templates
 * 		---------
 */

@import 'templates/equipe';
@import 'templates/service';
@import 'templates/carrieres';
@import 'templates/contact';
@import 'templates/terms';

@import 'admin/admin';
@import 'admin/wysiwg';



