@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7oopa7');
  src:  url('fonts/icomoon.eot?7oopa7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7oopa7') format('truetype'),
    url('fonts/icomoon.woff?7oopa7') format('woff'),
    url('fonts/icomoon.svg?7oopa7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-immigration:before {
  content: "\e90f";
}
.icon-linkedin2:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-fax:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e905";
}
.icon-acquisition:before {
  content: "\e906";
}
.icon-phone2:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e908";
}
.icon-linkedin:before {
  content: "\e909";
}
.icon-arrow-left2:before {
  content: "\e90a";
}
.icon-fiscalite:before {
  content: "\e90b";
}
.icon-conseils:before {
  content: "\e90c";
}
.icon-solutions:before {
  content: "\e90d";
}
.icon-close:before {
  content: "\e90e";
}
