$spinnerSpeed: 3s;
$doneSpeed: 3.1s;
$shadow: rgba(0, 0, 0, .08);

#ajax-wrap{
  position: relative;
}

#ajax-loader{
  padding: 100px 0;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  box-sizing: content-box;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: transparent;
    z-index: -1;
    transition: top 1.2s linear $doneSpeed;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: transparent;
    z-index: -1;
    transition: bottom 1.2s linear $doneSpeed;
  }

  &:before,
  &:after{
    box-sizing: content-box;
  }

  *,
  *:before,
  *:after{
    box-sizing: content-box;
  }

}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center;

  .progress__item {
    text-align: center;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border: 2px solid $colorAccent1;
    border-radius: 50%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: -3px;
      margin-left: 0px;
      width: 45px;
      height: 45px;
      border-top: solid 10px $colorAccent1;
      border-right: solid 10px $colorAccent1;
      border-top-right-radius: 100%;
      transform-origin: left bottom;
      -webkit-animation:spin $spinnerSpeed linear infinite;
      animation:spin $spinnerSpeed linear infinite;

    }
  }

  &.float {
    .progress__item {
      &:before {
        border-top-width: 2px;
        margin-top: 0px;
        height: 50px;
      }
    }
    &.shadow {
      &:before {
        border-top-width: 2px;
        margin-top: -41px;
        height: 50px;
      }
    }
  }

  &.shadow {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -43px 0 0 12px;
      width: 45px;
      height: 45px;
      border-top: solid 10px $shadow;
      border-right: solid 10px $shadow;
      border-top-right-radius: 100%;
      z-index: -1;
      transform-origin: left bottom;
      -webkit-animation:spin $spinnerSpeed linear infinite;
      animation:spin $spinnerSpeed linear infinite;
    }

    &:after { content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      color: $shadow;
      text-align: center;
      line-height: 100px;
      border: 2px solid $shadow;
      border-radius: 50%;
      margin: -40px 0 0 -40px;
      z-index: -1;
      transform-origin: center;
    }

  }

  &.done {
    .progress__item {
      opacity: 0;
      -webkit-animation: done $doneSpeed;
      animation: done $doneSpeed;
      transition: opacity .3s linear $doneSpeed;

      &:before {
        display: none;

      }
    }
    &:before {
      display: none;

    }
    &:after {
      opacity: 0;
      -webkit-animation: done $doneSpeed;
      animation: done $doneSpeed;
      transition: opacity .15s linear $doneSpeed;

    }

  }

}

@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% { transform: rotate(360deg); }
}