.home-hero{
  position: relative;

  width: 100%;
  height: 55vw;
  max-height: 855px;

  padding: 10vw;

  background: url('../images/hero/home.jpg') $light top right no-repeat;
  background-size: cover;

  .visual{
    background: url('../images/hero/home.jpg') $light top right no-repeat;
    background-size: cover;
  }



  .wrap{
    position: relative;
    width: 100%;
    height: 100%;
  }

  .content{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate( 0, -50%);

    width: 100%;
    max-width: 770px;

    margin-top: -45px;

  }

  @media all and (max-width: 1439px) {
    padding: 4vw;
    height: 49vw;
  }

  @media all and (max-width: 1174px) {
    height: 62vw;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media all and (max-width: 969px) {
    height: 70vw;
    background-position: 60% 0;

    .content{
      margin-top: 0;
    }
  }

  @media all and (max-width: 767px) {
    height: auto;
    background: $white;
    padding: 0 0;

    .wrap{
      width: 100%;
      height: auto;
    }

    .visual{
      position: relative;
      width: 100%;
      height: 40vw;
    }

    .content{
      position: relative;
      top: 0;
      left: 0;
      transform: none;

      padding: 40px 20px;
    }

    .title1{
      color: $dark;
      strong{
        color: $colorAccent1;
      }
    }

    .main-list li:before{
      background: $light;
    }
  }

}