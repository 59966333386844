
.bloc-map{
  position: relative;

  .map{
    position: relative;
    width: 100%;
    height: 30vw;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    z-index: 1;
  }

  .adresses{
    position: relative;
    width: 100%;
    height: auto;
    background: $white;

    @extend %clearfix;

    .bloc-adresse{
      position: relative;
      float: none;
      width: 100%;
      height: auto;
      padding: 6vw 40px;
      text-align: center;

      cursor: pointer;

      &.active{
        &:before{
          content: '';

          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 6px;
          background: $dark;
        }
      }

      .title3{
        text-align: center;
        font-size: 25px;
        margin: 0;
        padding: 0 0 25px 0;
      }

      p,
      a{
        padding: 0 0 20px 0;
        margin: 0;

        color: $black;
        font-family: $fontTitle;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 4px;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
      }

      p{
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      a{
        transition: all 0.35s ease;

        &:hover{
          color: $colorAccent1;
        }

      }

      i{
        font-size: 150%;
        color: $colorAccent2;
      }

    }

  }

  @media all and (max-width: 1280px) {
    .adresses{
      .bloc-adresse{
        p,
        a{
          font-size: 13px;
          letter-spacing: 2px;
        }
      }
    }
  }

  @media only screen and (max-width: 1174px) {
    .bloc-text{
      left: 10%;
    }
    .map{
      height: 375px;
    }
    .adresses{
      .bloc-adresse{

        p,
        a{
          letter-spacing: 1px;
        }

      }
    }
  }

  @media only screen and (max-width: 767px) {
    .adresses{
      .bloc-adresse{
        float: none;
        width: 100%;
        border: none;
        padding: 40px 20px;

        &:hover,
        &:first-child,
        &:last-child{
          border: none;

        }

        p,
        a{
          font-size: 12px;
        }

      }
    }
  }

}