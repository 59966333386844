.profil-equipe{
  @extend %clearfix;
  background: $white;
  border-bottom: 10px solid $white;

  overflow: hidden;

  .visual{
    position: fixed;
    top: 70px;
    left: 0;
    width: 40%;

    @extend %clearfix;

    .profil{
      position: relative;
      top: 0;

      float: right;
      width: 100%;
      height: auto;

      transition: all .55s ease-out;
    }
  }

  .informations{
    float: right;
    width: 60%;
    background: $light;
  }

  .main-text.list{
    li{
      margin-bottom: 35px;
    }
  }

  .article{
    padding: 75px;
  }

  .resume{
    .title1{
      color: $dark;
      padding-bottom: 0;
      font-size: 38px;
    }
    .title3{
      padding-bottom: 35px;
    }
    .main-text{
      padding-bottom: 15px;
      &.space{
        padding-bottom: 50px;
      }
    }
    .link-icon{
      .linkedin{
        top: 10px;
        font-size: 225%;
      }
    }
  }

  .about, .distinctions{
    background: $dark;

    .main-text,
    .title2{
      color: $white;
    }
  }

  @media all and (max-width: 1174px) {
    .article{
      padding: 6vw 40px;
    }

    .resume{
      .title1{
        font-size: 28px;
      }
      .title3{
        padding-bottom: 35px;
      }
      .main-text{

        .left-title,
        .right-list,
        .link-icon{
          font-size: 12px;
          letter-spacing: 1px;
          i{
            padding-left: 10px;
            padding-right: 5px;

            &.linkedin{
              top: 6px;
            }
          }
        }

        .left-title{
          max-width: 135px;
        }

        .right-list{
          padding-left: 135px;
        }

      }
    }

  }

  @media all and (max-width: 1024px) {
    .visual{
      position: relative;
      top: 0;
      float: none;
      width: 100%;

      .profil{
        display: block;
        float: none;
        margin: 0 auto;
        width: auto;
        height: 65vw;
      }
    }

    .informations{
      float: none;
      width: 100%;
    }
  }

  @media all and (max-width: 767px) {
    .article{
      padding: 40px 20px;
      &.resume{
        .main-text{
          text-align: left;
          .left-title,
          .right-list{
            text-align: left;
          }
          &.space{
            text-align: center;
          }
        }
      }
    }
  }

  @media all and (max-width: 599px) {
    .title1, .title2, .title3{
      text-align: center;
    }
    .resume{
        .main-text{
        .left-title{
          display: block;
          float: none;
          width: auto;
          max-width: inherit;
          text-align: center;
          padding-top: 15px;

        }
        .right-list{
          display: block;
          float: none;
          text-align: center;
          padding-left: 0;
        }
        .link-icon{
          display: block;
          text-align: center;
          padding: 5px 0;
        }
      }
    }
  }

}