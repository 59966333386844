.thumbs-publications{
  padding: 6vw 75px;

  .site-btn-center{
    padding-top: 60px;
  }

  .title2, .title3{
    text-align: center;
    max-width: 445px;
    margin: 0 auto;
  }

  .title2{
    padding-bottom: 60px;
  }

  .list-thumbs{
    position: relative;
    text-align: center;

    .thumb{
      position: relative;
      display: inline-block;

      width: 24%;
      max-width: 400px;
      padding: 0.4%;

      transform: none;
      transition: all ease 0.25s;

      &:hover{
        transform: scale(.98);
        .wrap{
          background-color: rgba( 0, 0, 0, .02);
        }

      }

      .image{
        position: relative;
        top: 15px;

        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        background-color: rgba( 0, 0, 0, 0);

        overflow: hidden;

        img{
          position: relative;
          top: -2px;
          display: block;
          width: 101%;
          height: auto;
        }

      }

      .wrap{
        border: 1px solid $light;
        border-radius: 6px;
        padding: 60px 40px;

        background-color: rgba( 0, 0, 0, 0);

        transform: none;
        transition: all ease 0.25s;

      }

      i{
        display: inline-block;
        font-size: 60px;
        padding-bottom: 35px;

      }

      .title3{
        max-width: 285px;
        min-height: 100px;
        color: $dark;
        font-size: 19px;
        line-height: 25px;
        letter-spacing: 2px;
        padding-bottom: 20px;
      }

      .main-text{
        padding-bottom: 60px;
        font-size: 14px;
        min-height: 185px;
      }

      .link{
        color: $colorAccent1;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 2px;
        font-weight: 600;
        text-transform: uppercase;

        transition: all ease 0.25s;
      }

    }
  }

  @media all and (max-width: 1649px) {
    .list-thumbs{
      .thumb{
        .wrap{
          padding: 60px 25px;
        }
      }
    }
  }

  @media all and (max-width: 1399px) {
    .list-thumbs{
      .thumb{
        padding: 0.2%;

        .wrap{
          padding: 45px 20px;
        }

        i{
          font-size: 50px;
          padding-bottom: 25px;

        }

        .title3{
          font-size: 17px;
          line-height: 1.4;
          letter-spacing: 1px;
          padding-bottom: 20px;
        }

        .main-text{
          font-size: 14px;
          line-height: 1.7;
          padding-bottom: 20px;
        }

        a{
          font-size: 14px;
          line-height: 1;
          letter-spacing: 1px;
        }

      }
    }
  }

  @media all and (max-width: 1174px) {
    padding: 6vw 40px;

    .list-thumbs{

      .thumb{
        width: 34%;
        max-width: 350px;
        padding: 1%;
      }

    }
  }

  @media all and (max-width: 969px) {
    .list-thumbs{

      .thumb{
        width: 45%;
      }

    }
  }

  @media all and (max-width: 767px) {
    padding: 40px 20px;

    .list-thumbs{

      .thumb{
        width: 100%;
        padding-top: 0;
        padding-bottom: 20px;
      }

    }

  }

}