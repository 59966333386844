.views{
  position: relative;
  width: 100%;
  height: auto;

  .switchers{
    @extend %clearfix;

    a{
      position: relative;
      float: left;
      width: 50%;
      background: $dark;
      font-family: $fontSpecial;
      color: $light;
      font-size: 18px;
      line-height: 1;
      font-weight: 400;
      text-align: center;
      letter-spacing: 2px;
      padding-top: 25px;
      padding-bottom: 25px;

      &.loner{
        width: 100%;
      }

      &.one-forth{
        width: 25%;
        height: 85px;
        font-size: 15px;
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid rgba( 0, 0, 0, .05);
      }

      &.active,
      &:hover{
        background: $darker;
      }

      &.active{
        &:after{
          content: '';

          position: absolute;
          left: 50%;
          bottom: -18px;

          width: 0;
          height: 0;
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
          border-top: 20px solid #202326;

          transform: translate( -50%, 0);
        }
      }

    }

    &.multiple a{
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;
      &.active{
        &:after{
          display: none;
        }
      }
    }

  }

  .view{
    display: none;
    &.active{
      display: block;
    }

    .main-text.list{
      li{
        text-transform: capitalize;
      }
    }
  }

  @media all and (max-width: 1024px) {
    .switchers{

      a{
        &.one-forth{
          width: 50%;
          font-size: 13px;
          letter-spacing: 0;

          &:after{
            display: none;
          }
        }
      }
    }
  }

  @media all and (max-width: 574px) {
    .switchers{

      &.multiple a{
        font-size: 12px;
      }

    }
  }

}