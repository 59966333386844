.title1{
  font-family: $fontTitle;
  text-transform: uppercase;
  color: $white;
  margin: 0;
  padding: 0 0 35px 0;
  font-size: 49px;
  line-height: 71px;
  font-weight: 700;
  letter-spacing: 5px;

  strong{
    display: block;
    color: $dark;
    font-family: $fontTitle;
    font-weight: 700;
  }

  @media all and (max-width: 1399px) {
    margin: 0;
    padding: 0 0 15px;
    font-size: 39px;
    line-height: 51px;
  }

  @media all and (max-width: 1174px) {
    margin: 0;
    padding: 0 0 10px;
    font-size: 31px;
    line-height: 45px;
    letter-spacing: 3px;
  }

  @media all and (max-width: 767px) {
    font-size: 22px;
    line-height: 1.3;
    font-weight: 500;

    strong{
      font-weight: 500;
    }
  }

  @media all and (max-width: 499px) {
    font-size: 17px;
    line-height: 1.5;
  }

  @media all and (max-width: 374px) {
    font-size: 16px;
  }


}

.title2{
  font-family: $fontTitle;
  text-transform: uppercase;
  color: $dark;
  margin: 0;
  padding: 0 0 45px 0;
  font-size: 22px;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: 5px;

  @media all and (max-width: 1174px) {
    font-size: 19px;
    line-height: 30px;
    padding: 0 0 25px 0;
  }

  @media all and (max-width: 969px) {
    font-size: 16px;
    line-height: 1.5;
  }

  @media all and (max-width: 374px) {
    font-size: 15px;
    letter-spacing: 3px;
  }

}

.title3{
  font-family: $fontSpecial;
  width: 100%;
  color: $colorAccent1;
  margin: 0;
  padding: 0 0 15px 0;
  font-size: 25px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 2px;

  @media all and (max-width: 1174px) {
    font-size: 22px;
    line-height: 24px;
  }

}

.title4{
  font-family: $fontTitle;
  color: $dark;
  margin: 0;
  padding: 0 0 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 2px;
}

.main-text{
  position: relative;
  font-family: $fontText;
  color: $dark;
  margin: 0;
  padding: 0 0 35px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  &.with-lettrine{
    padding-left: 85px;
    @media all and (max-width: 767px) {
      padding-left: 0;
    }
  }

  .with-lettrine{
    padding-left: 85px;
    @media all and (max-width: 767px) {
      padding-left: 0;
    }
  }

  &.list{
    margin: 0;
    list-style-type: none;

    li{
      position: relative;
      padding-left: 30px;

      &:before{
        content: '';

        position: absolute;
        top: 11px;
        left: 0;

        width: 16px;
        height: 16px;
        background: $dark;

        border-radius: 50%;

      }

      &:after{
        content: '';

        position: absolute;
        top: 13px;
        left: 2px;

        width: 12px;
        height: 12px;
        background: $dark;

        border: 2px solid $white;

        border-radius: 50%;

      }

    }
  }

  .left-title{
    float: left;
    width: 100%;
    max-width: 175px;
    color: $grey;
    font-family: $fontTitle;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    opacity: .54;
  }

  .right-list{
    display: block;
    width: 100%;
    padding-left: 175px;
    color: $dark;
    font-family: $fontText;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 22px;
    font-weight: 600;
    clear: right;
    padding-bottom: 10px;
  }

  .link-icon{
    color: $grey;
    font-family: $fontTitle;
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 20px;
    font-weight: 400;
    opacity: .54;

    i{
      position: relative;
      top: 4px;

      color: $darker;
      font-size: 160%;

      padding: 0 10px 0 15px;

      opacity: 1;

    }

    &:first-child{
      i{
        padding-left: 0;
      }
    }

  }

  @media all and (max-width: 1280px) {
    font-size: 15px;
  }

  @media all and (max-width: 1174px) {
    font-size: 14px;
    line-height: 1.8;
  }

  @media all and (max-width: 499px) {
    text-align: justify;
  }

}

.main-list{
  font-family: $fontSpecial;
  width: 100%;
  color: $dark;
  margin: 0;
  padding: 0 0 45px 0;
  font-size: 25px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 2px;

  list-style-type: none;

  li{
    display: inline-block;
    position: relative;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    margin: 20px 0;
    line-height: 1;

    &:before{
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: 6px;
      height: 25px;
      background: $white;
    }

    i{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      color: $colorAccent2;
      font-size: 135%
    }

  }

  @media all and (max-width: 1399px) {
    li{
      margin: 15px 0;
      font-size: 20px;
    }
  }

  @media all and (max-width: 1174px) {
    li{
      margin: 15px 0;
      font-size: 17px;
    }
  }

  @media all and (max-width: 499px) {
    li{
      font-size: 15px;
    }
  }

}

.site-hr-container{
  @extend %clearfix;
}

.site-hr{
  width: 80px;
  height: 6px;
  background: rgba(0, 0, 0, .25);
  margin: 0 auto 25px auto;
  padding: 0;
  border: none;

  &.white{
    background: rgba(255, 255, 255, .25);
  }

}

.lettrine{
  position: absolute;
  top: -6px;
  left: -12px;

  color: $colorAccent1;
  font-size: 125px;
  line-height: 1;

  @media all and (max-width: 767px) {
    position: relative;
    top: 0;
    left: 0;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
}