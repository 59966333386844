.terms{

  .bloc-terms{

    h1{
      position: relative;
      width: 100%;
      padding: 3% 0;
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
      color: $white;
      background: $dark;

      &:after{
        content: '';
        display: block;

        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $dark;

        position: absolute;
        top: auto;
        bottom: -15px;
        right: 50%;
        transform: translate(50%, 0);

      }

    }

    .controls{
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      padding: 1% 25px;

      .site-btn{
        font-size: 13px;
        padding: 15px 20px 15px 40px;

        background-image: url('../images/arrow-left.png');
        background-position: left 15px center;
        background-size: auto 27%;
        background-repeat: no-repeat;

        z-index: 5;

        &:hover{

          color: $white;

          background-color: $colorAccent1;

          background-image: url('../images/arrow-left.png');
          background-position: left 15px center;
          background-size: auto 27%;
          background-repeat: no-repeat;

        }
      }
    }

    h2{
      width: 100%;
      max-width: 1000px;
      padding: 1% 25px;
      margin: 0 auto;
      line-height: 1;
      font-weight: 400;
      text-align: left;
      color: $colorAccent1;

    }

    p, ul{
      width: 100%;
      max-width: 1000px;
      padding: 0 25px;
      margin: 0 auto;
      line-height: 1.7;
      text-align: left;
    }

    .main-text{
      padding-bottom: 25px;
    }

    .main-list{
      font-size: 18px;
    }

    ul{
      line-height: 1.3;
      padding: 2% 25px;
    }

  }

}