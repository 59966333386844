
.footer-nav{
  position: relative;
  padding: 15px 40px;

  background: $light;

  @extend %clearfix;

  .main-text{
    color: $dark;
    font-size: 12px;
    line-height: 1.4;
    padding: 0;
  }

  .left{
    float: left;
  }
  .right{
    float: right;
  }

  @media all and (max-width: 920px) {
    .main-text{
      font-size: 12px;
    }
  }

  @media all and (max-width: 767px) {

    padding: 15px 20px;

    .left{
      float: none;
      width: 100%;
      padding-bottom: 5px;
    }
    .right{
      float: none;
      width: 100%;
    }
    .main-text{
      width: 100%;
      text-align: center;
    }
  }

}