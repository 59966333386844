.info-carrieres{
  @extend %clearfix;
  background: $dark;

  overflow: hidden;

  .application-form{
    position: absolute;
    top: 70px;
    right: 0;
    width: 515px;

    padding: 7% 40px;

    @extend %clearfix;

    .title2{
      color: $white;
      text-align: center;
    }

    .main-form{
      label{
        color: $white;
        font-size: 14px;
        span{
          padding-bottom: 10px;
        }
      }
    }
  }

  .informations{
    float: left;
    width: calc(100% - 515px);
    background: $light;

  }

  .infos-header{
    position: relative;
    background: url('../images/hero/carrieres.jpg') #eee center center no-repeat;
    background-size: cover;

    padding: 10% 10%;

    .title1{
      color: $white;
      padding-bottom: 0;
      font-size: 37px;

    }
    .title2{
      color: $white;
      font-family: $fontSpecial;
      text-transform: none;
      padding-bottom: 0;
    }
  }

  .main-text.list{
    li{
      margin-bottom: 35px;
    }
  }

  .article{
    padding: 75px;
  }

  .resume{
    padding: 5% 10%;

    .content{
      background: $white;
      padding: 10%;
    }

    .title2{
      padding-bottom: 25px;
    }

    .col:first-child{
      border-right: 10px solid $light;
    }

    .col:last-child{
      border-left: 10px solid $light;
    }

    .main-text{
      padding-bottom: 15px;
      &.space{
        padding-bottom: 50px;
      }
    }
  }

  @media all and (max-width: 1665px) {
    .main-text.list{
      li{
        font-size: 13px;
        line-height: 1.5;
      }
    }
  }

  @media all and (max-width: 1340px) {
    .resume{
      .title2{
        text-align: center;
      }
      .col:first-child{
        float: none;
        width: 100%;
        padding-bottom: 35px;
        border: none;
      }

      .col:last-child{
        float: none;
        width: 100%;
        padding-bottom: 15px;
        border: none;
      }
    }
  }

  @media all and (max-width: 1174px) {
    .article{
      padding: 6vw 40px;
    }

    .resume{
      .title1{
        font-size: 28px;
      }
      .title3{
        padding-bottom: 35px;
      }
      .main-text{

        .left-title,
        .right-list,
        .link-icon{
          font-size: 12px;
          letter-spacing: 1px;
          i{
            padding-left: 10px;
            padding-right: 5px;

            &.linkedin{
              top: 6px;
            }
          }
        }

        .left-title{
          max-width: 135px;
        }

        .right-list{
          padding-left: 135px;
        }

      }
    }

  }

  @media all and (max-width: 1099px) {
    .application-form{
      width: 435px;
    }
    .informations{
      float: left;
      width: calc(100% - 435px);
    }
  }

  @media all and (max-width: 1024px) {

    .infos-header{

      .title1{
        font-size: 27px;
        line-height: 1;
      }
    }

    .application-form{
      position: relative;
      top: 0;
      float: none;
      width: 100%;

      .profil{
        display: block;
        float: none;
        margin: 0 auto;
        width: auto;
        height: 65vw;
      }
    }

    .informations{
      float: none;
      width: 100%;
    }
  }

  @media all and (max-width: 767px) {
    .application-form{
      padding: 40px 20px;
    }
    .article{
      padding: 40px 20px;
      &.resume{
        .main-text{
          text-align: left;
          .left-title,
          .right-list{
            text-align: left;
          }
          &.space{
            text-align: center;
          }
        }
      }
    }
  }

  @media all and (max-width: 599px) {
    .title1, .title2, .title3{
      text-align: center;
    }
    .resume{
        .main-text{
        .left-title{
          display: block;
          float: none;
          width: auto;
          max-width: inherit;
          text-align: center;
          padding-top: 15px;

        }
        .right-list{
          display: block;
          float: none;
          text-align: center;
          padding-left: 0;
        }
        .link-icon{
          display: block;
          text-align: center;
          padding: 5px 0;
        }
      }
    }
  }

}