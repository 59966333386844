.contact{

  .service-hero{
    height: 35vw;

    background: url('../images/hero/contact.jpg') $light top right no-repeat;
    background-size: cover;

    .content{
      text-align: center;
      max-width: inherit;
    }

    .title2{
      color: $white;
    }
  }

  &.isToronto {
    .service-hero{
      background-image: url('../images/hero/contact-toronto.jpg');
      background-position: center bottom;
    }
  }

  &.carrieres{
    .service-hero{
      background-image: url('../images/hero/carrieres.jpg');
    }
  }

  .bloc-details{
    .wrap{
      max-width: 570px;
      padding: 65px;
    }
  }

  @media all and (max-width: 1174px) {
    .bloc-details{
      .wrap{
        padding: 40px;
      }
    }
  }

  @media all and (max-width: 767px) {
    .bloc-details{
      .wrap{
        max-width: inherit;
        padding: 40px 20px;
      }
    }
    .title1{
      color: $white;
    }
  }

}