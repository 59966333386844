.thumbs-carrieres{
  padding: 6vw 75px;

  background: url('../images/home/carrieres.jpg') $light top right no-repeat;
  background-size: cover;

  .site-btn-center{
    padding-top: 60px;
  }

  .title2, .title3{
    text-align: center;
    max-width: 445px;
    margin: 0 auto;
  }

  .title2{
    color: $white;
    padding-bottom: 60px;
  }

  .list-thumbs{
    position: relative;
    text-align: center;

    .thumb{
      position: relative;
      display: inline-block;

      width: 24%;
      max-width: 325px;
      padding: 0.4%;

      transform: none;
      opacity: 1;
      transition: all ease 0.25s;

      &:hover{
        transform: scale(.98);
        opacity: .80;
      }

      .wrap{
        position: relative;
        border: 1px solid $light;
        padding: 50px 40px;
      }

      i{
        display: inline-block;
        font-size: 60px;
        padding-bottom: 35px;

      }

      .title3{
        max-width: 285px;
        min-height: 65px;
        color: $white;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: 2px;

        &.alternate{
          font-family: $fontTitle;
          padding-bottom: 40px;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 1px;
        }
      }

      .main-text{
        padding-bottom: 60px;
      }

      .bot-link{
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;

        padding: 15px;

        font-family: $fontTitle;
        color: $dark;
        background: $light;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        font-weight: 500;
        text-transform: uppercase;
      }

    }
  }

  @media all and (max-width: 1399px) {
    .list-thumbs{
      .thumb{
        padding: 0.2%;

        .wrap{
          padding: 45px 20px;
        }

        i{
          font-size: 50px;
          padding-bottom: 25px;

        }

        .title3{
          font-size: 17px;
          line-height: 1.4;
          letter-spacing: 1px;
          padding-bottom: 20px;
        }

        .main-text{
          font-size: 14px;
          line-height: 1.7;
          padding-bottom: 20px;
        }

        .bot-link{
          font-size: 14px;
          line-height: 1;
          letter-spacing: 1px;
        }

      }
    }
  }

  @media all and (max-width: 1174px) {
    padding: 6vw 40px;

    .list-thumbs{

      .thumb{
        width: 34%;
        max-width: 350px;
        padding: 1%;
      }

    }
  }

  @media all and (max-width: 969px) {
    .list-thumbs{

      .thumb{
        width: 45%;
      }

    }
  }

  @media all and (max-width: 767px) {
    padding: 40px 20px;

    .list-thumbs{

      .thumb{
        width: 100%;
        padding-top: 0;
        padding-bottom: 20px;
      }

    }

  }

}