.thumbs-equipe{
  position: relative;
  background: $light;
  padding: 6vw 75px;

  .title2, .title3{
    text-align: center;
    max-width: 445px;
    margin: 0 auto;
  }

  .title2{
    padding-bottom: 60px;
  }

  .list-thumbs{
    position: relative;
    text-align: center;

    .thumb{
      position: relative;
      display: inline-block;
      padding: 1%;
      max-width: 315px;

      transform: none;
      transition: all ease 0.25s;

      img{
        display: block;
        width: 100%;
        height: auto;
      }

      .wrap{
        position: relative;
      }

      .title4{
        text-align: left;
        padding: 15px 0;
        text-transform: uppercase;
      }

      .plus{
        position: absolute;
        right: 10px;
        bottom: -33px;

        width: 66px;
        height: 66px;
        border-radius: 50%;

        text-align: center;
        line-height: 73px;
        color: $white;
        font-size: 25px;

        background: $colorAccent1;
        box-shadow: 1px 2px 4px 0 rgba(0,0,0,.4);

        outline: none;

        .icon-plus::before{
          position: relative;
          left: -2px;

          display: inline-block;

          transform-origin: 12px 10px;
          width: 20px;
          height: 20px;

          transform: none;
          transition: all ease 0.25s;
        }
      }

      &:hover{
        transform: scale(.98);
        .plus{
          .icon-plus::before{
            transform: rotate(90deg);
          }
        }
      }
    }

  }

  @media all and (max-width: 1174px) {
    padding: 6vw 40px;

    .list-thumbs{

      .thumb{
        max-width: 250px;

        .title4{
          font-size: 13px;
        }

        .plus{
          right: 5px;
          bottom: -26px;
          width: 56px;
          height: 56px;
          line-height: 63px;
          font-size: 20px;
        }
      }

    }
  }

  @media all and (max-width: 767px) {
    padding: 40px 20px;
  }

}